import Box from 'components/commons/Box'
import ActionButton from 'components/commons/Button/Action'
import StateImage from 'components/commons/StateImage'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import {
  ItemsLableVa,
  ItemsLableVaBold,
  VaDesc,
  VaTitleLabel,
  WrapperItemsPending,
} from 'containers/domains/Wallet/DirectPaymentModal/styled'
import { getIdToken } from 'helpers/auth'
import dateFormatter from 'helpers/formats/date'
import NumberBaseFormatter from 'helpers/formats/number-base-format'
import { useTranslation } from 'react-i18next'

interface ISuccessStateProps {
  vaStatus: {
    invoice_id: string
    payment_method_code: string
    status: string
    paid_at: string
    checkout_at: string
    payment_method_name: string
    total: number
    ticket: string
  }
  itemType?: string
  handleClose: Function
  isOpen: boolean
}

export default function SuccessStatePayment({
  vaStatus,
  itemType,
  handleClose,
  isOpen,
}: ISuccessStateProps) {
  const { t } = useTranslation()
  const renderDescription = () => {
    if (itemType === 'event' && !getIdToken()) {
      return t('key_thankyou_your_purchase_event')
    } else {
      return t('key_thankyou_your_purchase_content')
    }
  }
  return (
    <ModalDrawer
      title={''}
      isOpen={isOpen}
      onClose={() => {
        handleClose()
      }}
      sxDialogContainer={{
        maxHeight: 750,
        '& .MuiDialogContent-root': {
          padding: '0px',
        },
        '& .MuiDialog-paper': {
          width: '500px',
        },
      }}
      sxDrawerContainer={{ padding: '16px 0px' }}
    >
      <Box>
        <StateImage
          type="success-withdraw"
          sx={{
            marginBottom: '16px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
        <VaTitleLabel>
          {' '}
          {t('transactionVaStatusWidgetPaidTitle')}
        </VaTitleLabel>
        <VaDesc>{renderDescription()}</VaDesc>
        <WrapperItemsPending>
          <ItemsLableVa>
            {t('transactionVAStatusDetailPendingWidgetTotalLabel')}
            <ItemsLableVaBold>
              Rp{NumberBaseFormatter(vaStatus?.total)}
            </ItemsLableVaBold>
          </ItemsLableVa>
          <ItemsLableVa>
            {t('transactionVAStatusDetailPaidWidgetPaidAtLabel')}
            <ItemsLableVaBold>
              {dateFormatter({
                date: vaStatus?.paid_at,
                format: 'DD MMM YYYY, HH:mm',
              })}
            </ItemsLableVaBold>
          </ItemsLableVa>
          <ItemsLableVa>
            {t(
              'transactionVAStatusDetailPaidWidgetPaymentMethodNameLabel',
            )}
            <ItemsLableVaBold>
              {vaStatus.payment_method_name}
            </ItemsLableVaBold>
          </ItemsLableVa>
        </WrapperItemsPending>
      </Box>
      <ActionButton
        sx={{
          padding: '12px 24px',
          width: '100%',
        }}
        onClick={() => {
          if (itemType === 'event' && !getIdToken()) {
            window.open(vaStatus.ticket, '_blank')
          } else {
            handleClose()
          }
        }}
      >
        {itemType === 'event' && !getIdToken()
          ? t('eventDetailsSeeTicketButton')
          : t('transactionVaStatusWidgetBtn')}
      </ActionButton>
    </ModalDrawer>
  )
}
