import VoucherErrorIcon from 'assets/svg/session/session-unload.svg'
import { styled, Tooltip } from '@mui/material'
import ErrorIcon from 'assets/svg/session/session-unload.svg'
import InfoIcon from '@mui/icons-material/Info'
import UnhappyBooking from 'assets/svg/session/unhappy_booking.svg'
import EventSelectedSeatErrorSVG from 'assets/svg/event/event-selected-seat-error.svg'
import EventAdjacentSeatErrorSVG from 'assets/svg/event/event-adjacent-seat-error.svg'
import WaitingMoneyErrorSVG from 'assets/svg/waiting_money.svg'
import HelpIcon from '@mui/icons-material/Help'

import Button from 'components/commons/Button'
import Stack from 'components/commons/Stack'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'

export const StyledIframe = styled('iframe')({
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
})

export const StyledWaitingMoneyIcon = styled(WaitingMoneyErrorSVG)({
  marginBottom: '16px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const StyledVoucherErrorIcon = styled(VoucherErrorIcon)({
  marginBottom: '16px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const StyledErrorIcon = styled(ErrorIcon)({
  marginBottom: '16px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const EventSelectedSeatErrorIcon = styled(
  EventSelectedSeatErrorSVG,
)({
  marginBottom: '16px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const EventAdjacentSeatErrorIcon = styled(
  EventAdjacentSeatErrorSVG,
)({
  marginBottom: '16px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const StyledUnhappyBookingIcon = styled(UnhappyBooking)({
  marginBottom: '16px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const StyledIconRadio = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.primary,
  alignItems: 'center',
}))
export const StyledIconImg = styled('img')({
  objectFit: 'cover',
})
export const StyledInfoRadio = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    whiteSpace: 'pre-wrap',
  },
}))
export const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  marginRight: 9,
  color: theme.palette.info.main,
  fontSize: '18px',
}))

export const StyledWrapperInfo = styled(Box)({
  display: 'flex',
  fontSize: 12,
  marginBottom: '-3px',
  alignItems: 'center',
})
export const ErrorLabel = styled(Stack)(({ theme }) => ({
  ...theme.typography.h3,
  lineHeight: theme.typography.mediumRegular.lineHeight,
  textAlign: 'center',
  letterSpacing: '0.0015em',
  color: theme.palette.text.primary,
  marginBottom: 8,
}))
export const ErrorDesc = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.normalRegular,
  textAlign: 'center',
  marginBottom: 24,
}))

export const VaTitleLabel = styled(Stack)(({ theme }) => ({
  ...theme.typography.h3,
  lineHeight: theme.typography.mediumRegular.lineHeight,
  textAlign: 'center',
  letterSpacing: '0.0015em',
  color: theme.palette.text.primary,
  marginBottom: 8,
}))
export const VaDesc = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.normalRegular,
  textAlign: 'center',
  marginBottom: 16,
}))

export const VaDescBold = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.normalBold,
  textAlign: 'center',
  marginBottom: 16,
  display: 'inline',
}))

export const WrapperItemsPending = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.tertiery,
  padding: 16,
  paddingBottom: 5,
  borderRadius: 5,
  marginBottom: 24,
}))
export const ItemsLableVa = styled(Typography)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.primary,
  fontSize: 12,
  marginBottom: 15,
}))
export const ItemsLableVaBold = styled(Typography)({
  fontWeight: 700,
  marginLeft: 'auto',
  fontSize: 14,
  textTransform: 'capitalize',
})

export const RadioWrapper = styled(Box)({
  display: 'block',
  alignItems: 'center',
})

export const TopUpButton = styled(Button)({
  padding: 12,
  borderRadius: 24,
  alignItems: 'center',
  justifyContent: 'center',
})

export const StyledHelpIcon = styled(HelpIcon)(({ theme }) => ({
  fontSize: '14px !important',
  color: theme.palette.text.primary,
  marginLeft: 4,
  cursor: 'pointer',
  svg: {
    width: '14px',
    height: '14px',
  },
}))
export const StyledTooltips = styled(Tooltip)({
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})
export const StyledTooltipsBody = styled(Box)(({ theme }) => ({
  padding: '3px 6px',
  textAlign: 'center',

  fontSize: '10px',
  fontWeight: '400',
  backgroundColor: theme.palette.text.primary,
  color: theme.palette.background.tertiery,
}))
export const StyledLink = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.tiptip[500],
  textDecoration: 'none',
  fontWeight: '700',
}))
export const SubmitModalButton = styled(Button)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
  borderRadius: 8,
  width: '100%',
  padding: '12px 16px',
  background:
    'linear-gradient(89.95deg, #FF223F 0.05%, #CB1C4F 99.96%)',
  '&.Mui-disabled': {
    background: theme.palette.text.primary,
  },
}))
export const CancelModalButton = styled(Button)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
  borderRadius: 8,
  width: '100%',
  padding: '12px 16px',
  background:
    'linear-gradient(89.95deg, #FF223F 0.05%, #CB1C4F 99.96%)',
  '&.Mui-disabled': {
    background: theme.palette.background.tertiery,
  },
}))
