import { useState, useEffect } from 'react'
import { Skeleton } from '@mui/material'

import DirectPaymentDrawerContent from 'components/domains/Wallet/DirectPaymentDrawerContent'
import trackEvent from 'src/trackers'
import { useStoreActions, useStoreState } from 'stores/hooks'
import VoucherInbox from 'containers/domains/Wallet/VoucherInbox'
import VoucherDetails from 'containers/domains/Wallet/VoucherDetails'

import PaymentInstruction from 'containers/domains/Wallet/PaymentInstruction'
import VoucherErrorContent from 'components/domains/Wallet/VoucherError'
import SuccessStatePayment from 'components/domains/Wallet/SuccessVaPayment'
import PendingStateContent from 'components/domains/Wallet/PendingVaPayment'

//store
import { getCheckoutInstruction, getVaStatus } from 'services/payment'
import { sendLog } from 'helpers/log'
import { IProductsProps } from 'services/payment'
import { generateShortUuid } from 'helpers/generate/uuid'
import ErrorPayment from 'components/domains/Payment/ErrorPayment'
import SuccessBuyCoin from 'components/domains/Payment/SuccessBuyCoin'
import { useRouter } from 'next/router'

import { getIdToken } from 'helpers/auth'
import { useCommunityStore } from 'stores/domains/Social'
import Box from 'components/commons/Box'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import ActionButton from 'components/commons/Button/Action'
import PaymentMethodRadio from 'containers/domains/Wallet/PaymentMethodRadio'
import FeesPaymentDetail from 'components/domains/Wallet/FeesPaymentDetail'
import {
  LabelWrapperInfo,
  StyledInfoIcon,
  StyledInfoLabel,
} from 'containers/domains/TransactionHistory/TransactionCard/styled'

function DrawerSkeleton() {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        height={36}
        width={'100%'}
        sx={{ background: 'lightgrey', borderRadius: '8px' }}
      />
    </Box>
  )
}

interface IDirectPaymentDrawer {
  title: string
  trackerData?: any
  onInit: Function
  onClose: Function
  onPaymentClose: Function
  onPayNowClick: Function
  onCancelTransaction?: Function
  itemType: string
  useVoucher?: boolean
  trackerCompletedPurchase?: Function
  paymentInstruction?: {
    is_show: boolean
    title: string
    body: string
    invoice_id: string
    payment_method_code: string
  }
  modalInstruction?: boolean
  setModalInstruction?: Function
  idempotentKey: string
  selectedProducts?: {
    products: Array<IProductsProps>
    payment_method: string
    checkout_source: string
    user_mask_id?: string
    voucher_code: string
    idempotentKey: string
  }
  setIdempotentKey: Function
  setPaymentType?: Function
  preCheckout?: Function
  setGuestPendingTransaction?: Function
  t?: any
}

function DirectPaymentModal({
  title,
  trackerData,
  onInit = () => {},
  onClose = () => {},
  onPaymentClose = () => {},
  onPayNowClick = () => {},
  onCancelTransaction = () => {},
  itemType,
  useVoucher = true,
  paymentInstruction,
  modalInstruction,
  setModalInstruction,
  idempotentKey,
  selectedProducts,
  setIdempotentKey,
  setPaymentType,
  preCheckout,
  setGuestPendingTransaction,
  t,
}: IDirectPaymentDrawer) {
  const [isConfirmationDrawerShown, setIsConfirmationDrawerShown] =
    useState(true)
  const [arrayFees, setArrayFees] = useState([])
  const [arrayFeesPromo, setArrayFeesPromo] = useState([])
  const [cartData, setCartData] = useState(null)
  const [cartValues, setCartValues] = useState(null)
  const [isLoadingCart, setIsLoadingCart] = useState(false)
  const [checkoutValues, setCheckoutValues] = useState(null)
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false)
  const [errorMessageCheckout, setErrorMessageCheckout] = useState('')
  const [isErrorCheckout, setIsErrorCheckout] = useState<
    boolean | any
  >(false)
  const [isErrorCart, setIsErrorCart] = useState<boolean | any>(false)
  const [isErrorVaInstruction, setIsErrorVaInstruction] = useState<
    boolean | any
  >(false)
  const [errorVaStatus, setErrorVaStatus] = useState<boolean | any>(
    false,
  )

  const [voucherCode, setVoucherCode] = useState(null)
  const [checkoutVoucherError, setCheckoutVoucherError] =
    useState(false)
  const [isVoucherInbox, setIsVoucherInbox] = useState(false)
  const [isVoucherDetail, setIsVoucherDetail] = useState(false)
  const [paymentInstructionList, setPaymentInstructionList] =
    useState(null)
  const [vaStatus, setVaStatus] = useState(null)
  const [isPendingState, setIsPendingState] = useState(false)
  const [isSuccessState, setIsSuccessState] = useState(false)
  const [isLoadingVaInstruction, setIsLoadingVaInstruction] =
    useState(false)
  const [isSuccessStateCoin, setIsSuccessStateCoin] = useState(false)
  const [invoiceVa, setInvoiceVa] = useState('')
  const [selectedPaymentType, setSelectedPaymentType] = useState(0)
  const [isAdditionalFeesOpen, setIsAdditionalFeesOpen] =
    useState(false)

  const { isReady } = useRouter()
  const { voucherData, voucherDetails, isLoading } = useStoreState(
    (state) => state.voucherModel,
  )

  const {
    setVoucherData,
    setVoucherDetails,
    setInputVoucherCode,
    setIsError,
  } = useStoreActions((action) => action.voucherModel)
  const { setShowTopUpModal, setTopUpCoinStatus } = useStoreActions(
    (actions) => ({
      ...actions.topUp,
    }),
  )
  const { topUpCoinStatus } = useStoreState((actions) => ({
    ...actions.topUp,
  }))
  const { clearUserAdditionalInfo } = useStoreActions(
    (action) => action.eventPersonalQuestions,
  )
  const { community } = useCommunityStore((state) => ({
    community: state.community,
  }))

  const modalName = () => {
    switch (itemType) {
      case 'content':
        return 'PURCHASE_CONTENT'
      case 'event':
        return 'PURCHASE_ETICKET'
      default:
        return ''
    }
  }

  const addCartHandler = async () => {
    try {
      setTopUpCoinStatus(false)
      setIsLoadingCart(true)
      setCartValues(await onInit())
    } catch (error) {
      setIsErrorCheckout(error)
      setIsErrorCart(error)
      sendLog(
        `Error: ${error?.response?.data?.code} - ${error?.response?.data?.message}`,
      )
      if (error?.response?.status === 429) {
        handleModalCleaner()
        onClose()
      }
    } finally {
      setIsLoadingCart(false)
    }
  }

  const checkoutHandler = async (cartValues) => {
    try {
      setIsLoadingCheckout(true)
      setCheckoutValues(
        await onPayNowClick(
          cartValues,
          itemType === 'event' ||
            itemType === 'content' ||
            itemType === 'coin'
            ? cartValues?.payment_method[selectedPaymentType]?.code
            : 'OTHER_METHOD',
        ),
      )
    } catch (error) {
      setIsErrorCheckout(error)
      setErrorMessageCheckout(error?.response?.data?.message)
      sendLog(
        `Error: ${error?.response?.data?.code} - ${error?.response?.data?.message}`,
      )
      if (error?.response?.data?.code === 'INVALID_VOUCHER') {
        setCheckoutVoucherError(true)
      }
      if (error?.response?.status !== 500) {
        setIdempotentKey(generateShortUuid())
      }
      if (error?.response?.status === 429) {
        handleModalCleaner()
        onClose()
      }
    } finally {
      setIsLoadingCheckout(false)
    }
  }

  const checkStatusHandler = async (invoiceId) => {
    try {
      setVaStatus(
        await getVaStatus({
          invoice_id: invoiceId,
          payment_method: 'H2H_BCA_VA',
          idempotentKey: idempotentKey,
        }),
      )
    } catch (error) {
      sendLog(`Error: ${error?.code} - ${error?.message}`)
      setErrorVaStatus(error)
    }
  }

  const instructionsHandler = async (invoiceId, method) => {
    setIsLoadingVaInstruction(true)
    try {
      setPaymentInstructionList(
        await getCheckoutInstruction({
          invoice_id: invoiceId,
          payment_method: method,
          idempotentKey: idempotentKey,
        }),
      )
      setIsLoadingVaInstruction(false)
    } catch (error) {
      setIsErrorVaInstruction(error)
      sendLog(`Error: ${error?.code} - ${error?.message}`)
      setModalInstruction(false)
      setIsConfirmationDrawerShown(false)
    } finally {
      setIsLoadingVaInstruction(false)
    }
  }

  const stateCleaner = () => {
    setCheckoutValues(null)
    setCartData(null)
  }

  const paymentTypeHandler = () => {
    if (voucherData) {
      setPaymentType(
        voucherData?.payment_method[selectedPaymentType]?.code,
      )
    } else {
      setPaymentType(
        cartValues?.payment_method[selectedPaymentType]?.code,
      )
    }
  }

  const handlePaymentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedPaymentType(parseInt(event.target.value))
    paymentTypeHandler()
  }
  const handleExternalPayment = (url) => {
    window.open(url, '_blank')
    setSelectedPaymentType(0)
    modalPaymentExternalCloser()
    if (itemType === 'coin') {
      preCheckout()
    }
  }

  useEffect(() => {
    if (topUpCoinStatus) {
      addCartHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topUpCoinStatus])

  useEffect(() => {
    addCartHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (vaStatus?.status?.toLowerCase() === 'pending') {
      setIsSuccessState(false)
      setIsPendingState(true)
    } else if (vaStatus?.status?.toLowerCase() === 'paid') {
      setIsPendingState(false)
      setIsSuccessState(true)
    } else if (vaStatus?.status === '') {
      stateCleaner()
      setIsSuccessState(false)
      setIsPendingState(false)
      setIsConfirmationDrawerShown(true)
    } else {
      setIsSuccessState(false)
      setIsPendingState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaStatus])

  useEffect(() => {
    if (cartValues) {
      if (itemType === 'session') {
        setCartData(cartValues)
      } else if (itemType === 'subscription') {
        setCartData(cartValues.payment_info)
      } else {
        paymentTypeHandler()
        setCartData(cartValues?.payment_method[selectedPaymentType])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentType, cartValues])

  useEffect(() => {
    if (cartData) {
      if (itemType === 'event' || itemType === 'content') {
        setArrayFees([
          {
            label: t('paymentDetailWidgetAdditionalFeeLabel'),
            price: cartData?.price?.total_additional_fee,
          },
        ])
      } else {
        setArrayFees([
          {
            label:
              itemType === 'session' || itemType === 'subscription'
                ? t('paymentDetailWidgetAdditionalFeeLabel')
                : cartData?.admin_fee_title,
            price:
              itemType === 'subscription'
                ? cartData?.admin_fee
                : cartData?.transaction_fee,
          },
          {
            label: 'PPN',
            price: cartData?.tax,
          },
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, voucherData, cartValues])

  useEffect(() => {
    if (cartData && voucherCode !== null && arrayFees) {
      setArrayFeesPromo([
        ...arrayFees,
        {
          label: cartData?.voucher_info,
          price: cartData?.price?.voucher_discount,
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, voucherCode, cartValues, arrayFees])

  useEffect(() => {
    if (
      checkoutValues &&
      cartData &&
      !isErrorCart &&
      !isErrorCheckout
    ) {
      setInvoiceVa(checkoutValues?.invoice_id)
      if (
        itemType === 'event' ||
        itemType === 'content' ||
        itemType === 'coin'
      ) {
        //free
        if (cartData?.price?.total_price === 0) {
          return window.location.reload()
        }
        //BCA VA
        else if (
          checkoutValues?.invoice_url === '' &&
          checkoutValues?.payment_method_code === 'H2H_BCA_VA'
        ) {
          setModalInstruction(true)
        } //tiptip coin
        else if (
          cartValues?.payment_method[selectedPaymentType]?.code ===
            'TIPTIP_COIN' &&
          checkoutValues?.status?.toUpperCase() === 'COMPLETED'
        ) {
          setIsSuccessStateCoin(true)
        } //default
        else {
          if (checkoutValues?.status?.toUpperCase() === 'FAILED') {
            setIsErrorCheckout(true)
          } else {
            handleExternalPayment(checkoutValues?.invoice_url)
          }
        }
      }
      //default
      else {
        if (checkoutValues?.status?.toUpperCase() === 'FAILED') {
          setIsErrorCheckout(true)
        } else {
          handleExternalPayment(checkoutValues?.invoice_url)
        }
      }
      //trackers only
      if (itemType === 'content')
        trackEvent.content(
          'purchase_content_completed',
          trackerData,
          {
            content_admin_fee: cartData.transaction_fee,
            content_tax_value: cartData.tax,
            content_total_price: cartData?.price?.total_price,
            content_discount: voucherData
              ? cartData?.price?.voucher_discount
              : '',
            voucher_code: cartData?.voucher_code,
          },
        )
      //end of trackers only
      if (
        itemType === 'event' &&
        checkoutValues?.status?.toUpperCase() !== 'FAILED'
      ) {
        clearUserAdditionalInfo()
        eventPaymentTracker('purchase_eticket_completed')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    voucherData,
    cartValues,
    checkoutValues,
    cartData,
    selectedPaymentType,
  ])

  const voucherTracker = (eventName, voucherCode) => {
    return trackEvent.payment(eventName, trackerData, {
      modal_name: 'PURCHASE_CONTENT',
      voucher_code: voucherCode,
    })
  }
  const eventPaymentTracker = (eventName) => {
    return trackEvent.event(eventName, trackerData, {
      modal_name: modalName(),
      user_type: getIdToken() ? 'supporter' : 'guest',
      event_quantity: selectedProducts.products[0].tickets[0].qty,
      event_admin_fee: cartData?.transaction_fee,
      event_tax_value: cartData?.tax,
      event_discount: voucherData
        ? cartData?.price?.voucher_discount
        : '',
      event_total_price: totalPriceHandler('total_price'),
      event_subtotal: totalPriceHandler('sub_total'),
      is_member_community: community.is_member || '',
      voucher_code: voucherData ? voucherCode : '',
    })
  }

  const handleVoucherClear = () => {
    setVoucherCode(null)
    setVoucherData(null)
    setInputVoucherCode('')
    setIsError(false)
    setSelectedPaymentType(0)
  }
  const voucherInboxOpen = () => {
    setIsVoucherInbox(true)
    setIsConfirmationDrawerShown(false)
  }
  const voucherInboxClose = () => {
    setIsVoucherInbox(false)
    setIsConfirmationDrawerShown(true)
    if (!voucherData) {
      handleVoucherClear()
    }
  }
  const voucherDetailOpen = () => {
    setIsVoucherInbox(false)
    setIsVoucherDetail(true)
    setIsConfirmationDrawerShown(false)
  }
  const voucherDetailClose = () => {
    setIsVoucherDetail(false)
    setIsVoucherInbox(true)
    setVoucherDetails(null)
  }

  const handleModalCleaner = () => {
    setIsErrorCart(false)
    setIsErrorVaInstruction(false)
    setIsErrorCheckout(false)
    setIsConfirmationDrawerShown(false)
    setCheckoutValues(null)
    handleVoucherClear()
    setSelectedPaymentType(0)
    if (itemType === 'coin') {
      trackEvent.payment('cancel_topup_payment', trackerData, {
        modal_name: 'TOPUP_PAYMENT_METHOD',
        trnx_fee: cartData?.transaction_fee,
        total_payment: cartData?.total,
        total_topup_coin: trackerData?.totalCoinTopUp,
      })
    }
  }
  const modalVaStatusCloser = () => {
    setSelectedPaymentType(0)
    setModalInstruction(false)
    setIsSuccessState(false)
    stateCleaner()
    onPaymentClose()
    if (itemType === 'coin') {
      preCheckout()
    }
  }

  const modalPaymentExternalCloser = () => {
    handleVoucherClear()
    onPaymentClose()
    setCheckoutValues(null)
  }

  useEffect(() => {
    if (
      voucherData?.payment_method?.length <
      cartValues?.payment_method?.length
    ) {
      setSelectedPaymentType(1)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, voucherData, cartValues])

  useEffect(() => {
    if (voucherData && isReady) {
      setVoucherCode(
        voucherData?.payment_method[selectedPaymentType]
          ?.voucher_code,
      )
      voucherInboxClose()
      paymentTypeHandler()
      setCartData(voucherData?.payment_method[selectedPaymentType])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherData, selectedPaymentType])

  useEffect(() => {
    if (itemType !== 'subscription' && itemType !== 'session') {
      paymentTypeHandler()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentType])

  useEffect(() => {
    if (voucherDetails) {
      voucherDetailOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherDetails])

  useEffect(() => {
    const paymentOptions = voucherData || cartValues

    if (paymentOptions) {
      const coinPayment = paymentOptions?.payment_method?.find(
        (item) => item.code === 'TIPTIP_COIN',
      )
      const coinPaymentIndex =
        paymentOptions?.payment_method?.findIndex(
          (item) => item.code === 'TIPTIP_COIN',
        )

      if (
        coinPayment?.coin?.balance >= coinPayment?.coin?.total_price
      ) {
        setSelectedPaymentType(coinPaymentIndex)
      }
    }
  }, [setSelectedPaymentType, voucherData, cartValues])

  const instructionChecker = () => {
    if (
      paymentInstruction?.invoice_id &&
      paymentInstruction?.payment_method_code === 'H2H_BCA_VA'
    ) {
      instructionsHandler(
        paymentInstruction?.invoice_id,
        paymentInstruction?.payment_method_code,
      )
    } else if (checkoutValues?.payment_method_code === 'H2H_BCA_VA') {
      instructionsHandler(
        invoiceVa ? invoiceVa : paymentInstruction?.invoice_id,
        checkoutValues?.payment_method_code,
      )
    }
  }

  useEffect(() => {
    if (modalInstruction) instructionChecker()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInstruction, modalInstruction])

  const totalPriceHandler = (priceType) => {
    if (itemType === 'event' || itemType === 'content') {
      return cartData?.price?.[priceType]
    } else {
      return cartData?.[priceType]
    }
  }
  const basePriceHandler = () => {
    if (itemType === 'event' || itemType === 'content') {
      return cartData?.price?.sub_total
    } else if (itemType === 'subscription') {
      return cartData?.price
    } else {
      return cartData?.sub_total
    }
  }

  const topUpCoinHandler = () => {
    setShowTopUpModal(true)
    handleModalCleaner()
    onClose()
    setIsConfirmationDrawerShown(true)

    trackEvent.payment('click_topup', trackerData, {
      modal_name: modalName(),
    })
  }

  const PaymentContent = () => {
    return (
      <Box>
        {(isLoadingCart || isLoadingCheckout) && <DrawerSkeleton />}

        <>
          {cartValues?.payment_information &&
            itemType === 'event' && (
              <LabelWrapperInfo>
                <StyledInfoIcon />
                <StyledInfoLabel>
                  {cartValues?.payment_information}
                </StyledInfoLabel>
              </LabelWrapperInfo>
            )}
          {itemType !== 'session' && itemType !== 'subscription' && (
            <PaymentMethodRadio
              paymentType={selectedPaymentType}
              cartValues={voucherData ? voucherData : cartValues}
              handlePaymentTypeChange={handlePaymentTypeChange}
              topUpCoinHandler={topUpCoinHandler}
              t={t}
            />
          )}
          <DirectPaymentDrawerContent
            voucherCode={voucherCode}
            items={[
              {
                label:
                  itemType === 'subscription' ? (
                    <>
                      {cartValues.title} <br />
                      {Math.floor(cartValues?.duration / 30)} Bulan
                    </>
                  ) : (
                    t('paymentDetailWidgetBasePriceLabel')
                  ),
                price: basePriceHandler(),
              },
            ]}
            fees={voucherData ? arrayFeesPromo : arrayFees}
            totalPrice={totalPriceHandler(
              itemType === 'event' || itemType === 'content'
                ? 'total_price'
                : 'total',
            )}
            totalPriceCoin={cartData?.coin?.total_price}
            subTotalPrice={totalPriceHandler(
              'total_price_before_discount',
            )}
            handleVoucherClear={handleVoucherClear}
            voucherInboxOpen={voucherInboxOpen}
            itemType={itemType}
            useVoucher={useVoucher}
            isEligibleVoucher={
              voucherData
                ? cartData?.disable_voucher_reason
                : (itemType === 'event' || itemType === 'content') &&
                  cartValues?.payment_method[selectedPaymentType]
                    ?.disable_voucher_reason
            }
            isCoinMethod={
              (itemType === 'event' || itemType === 'content') &&
              cartValues?.payment_method[selectedPaymentType]
                ?.code === 'TIPTIP_COIN'
            }
            setIsAdditionalFeesOpen={setIsAdditionalFeesOpen}
          />
        </>
      </Box>
    )
  }

  const submitButtonLabel = () => {
    if (voucherData || !useVoucher) {
      if (cartData?.disable_voucher_reason) {
        return t('key_pay_without_promo')
      } else {
        return t('paymentConfirmationBottomSheetButton')
      }
    } else {
      return t('key_pay_without_promo')
    }
  }

  const ErrorPaymentContainer = () => {
    return (
      <ErrorPayment
        itemType={itemType}
        onClose={(errorCode) => {
          handleModalCleaner()
          setTimeout(() => onClose(errorCode), 200)
        }}
        continueTransaction={(transaction) => {
          if (transaction.payment_method_code === 'H2H_BCA_VA') {
            setGuestPendingTransaction(transaction)
            setModalInstruction(true)
          } else handleExternalPayment(checkoutValues?.invoice_url)
        }}
        cancelTransaction={async (invoice_id) => {
          await onCancelTransaction(invoice_id)
          onPaymentClose()
        }}
        retryPaymentModal={setIsConfirmationDrawerShown}
        setSelectedPaymentType={setSelectedPaymentType}
        errorMessageCheckout={errorMessageCheckout}
        setCheckoutValues={setCheckoutValues}
        isErrorCart={isErrorCart}
        setIsErrorCart={setIsErrorCart}
        isErrorCheckout={isErrorCheckout}
        setIsErrorCheckout={setIsErrorCheckout}
        isErrorVaInstruction={isErrorVaInstruction}
        setIsErrorVaInstruction={setIsErrorVaInstruction}
        isLoadingVaInstruction={isLoadingVaInstruction}
        isLoadingCart={isLoadingCart}
        isLoadingCheckout={isLoadingCheckout}
        setShowTopUpModal={topUpCoinHandler}
        setShowPaymentMethod={() => {
          setIsErrorCart(false)
          setIsErrorVaInstruction(false)
          setIsErrorCheckout(false)
          setCheckoutValues(null)
          handleVoucherClear()
          setIsErrorCheckout(false)
          setIsConfirmationDrawerShown(true)
        }}
        errorVaStatus={errorVaStatus}
      />
    )
  }

  if (checkoutValues?.status?.toUpperCase() === 'COMPLETED') {
    return (
      <SuccessBuyCoin
        isSuccessStateCoin={isSuccessStateCoin}
        submitAction={() => {
          setIsSuccessStateCoin(false)
          modalPaymentExternalCloser()
        }}
        coinInfo={checkoutValues?.coin_info}
      />
    )
  }

  return (
    <>
      {cartData?.price?.additional_fee && (
        <FeesPaymentDetail
          paymentMethod={cartData}
          isAdditionalFeesOpen={isAdditionalFeesOpen}
          setIsAdditionalFeesOpen={setIsAdditionalFeesOpen}
        />
      )}
      {!checkoutVoucherError &&
        !(
          isErrorCart?.response?.status === 429 ||
          isErrorCheckout?.response?.status === 429 ||
          isErrorVaInstruction?.response?.status === 429
        ) && <ErrorPaymentContainer />}
      {!(invoiceVa || paymentInstruction?.invoice_id) &&
        cartData &&
        cartValues && (
          <ModalDrawer
            title={title}
            isOpen={isConfirmationDrawerShown}
            onClose={() => {
              handleModalCleaner()
              onClose()
            }}
            sxDialogContainer={{
              maxHeight: 750,
              '& .MuiDialogContent-root': {
                padding: '0px',
              },
              '& .MuiDialog-paper': {
                width: '500px',
              },
            }}
            sxDrawerContainer={{ padding: '16px 0px' }}
          >
            <PaymentContent />
            {!isLoadingCart && (
              <ActionButton
                id={`b-wallet-${itemType}-buy-now`}
                sx={{
                  padding: '12px 24px',
                  width: '100%',
                }}
                onClick={() => {
                  if (itemType === 'content') {
                    voucherTracker(
                      'purchase_content_confirm',
                      voucherCode,
                    )
                  }
                  if (itemType === 'event') {
                    eventPaymentTracker('purchase_eticket_confirm')
                  }
                  if (itemType === 'coin') {
                    trackEvent.payment(
                      'topup_completed',
                      trackerData,
                      {
                        modal_name: 'TOPUP_PAYMENT_METHOD',
                        trnx_fee: cartData?.transaction_fee,
                        total_payment: cartData?.total,
                        total_topup_coin: trackerData?.totalCoinTopUp,
                      },
                    )
                  }
                  checkoutHandler(cartValues)
                  setIsConfirmationDrawerShown(false)
                }}
              >
                {submitButtonLabel()}
              </ActionButton>
            )}
          </ModalDrawer>
        )}
      <ModalDrawer
        title={'Voucher Inbox'}
        isOpen={isVoucherInbox}
        onClose={() => {
          voucherInboxClose()
        }}
        sxDialogContainer={{
          '& .MuiDialogContent-root': {
            padding: '0px',
          },
          '& .MuiDialog-paper': {
            width: '500px',
          },
        }}
        sxDrawerContainer={{ padding: '16px 0px' }}
      >
        <VoucherInbox
          handleVoucherClear={handleVoucherClear}
          voucherInboxOpen={voucherInboxOpen}
          cartValues={cartValues}
          trackerData={trackerData}
          page={itemType === 'content' || itemType === 'event'}
          idempotentKey={idempotentKey}
          selectedProducts={selectedProducts}
          itemType={itemType}
          community={community}
          t={t}
        />
      </ModalDrawer>

      <ModalDrawer
        title={'Voucher Detail'}
        isOpen={isVoucherDetail}
        onClose={() => {
          voucherDetailClose()
        }}
        sxDialogContainer={{
          '& .MuiDialogContent-root': {
            padding: '0px',
          },
          '& .MuiDialog-paper': {
            width: '500px',
          },
        }}
        sxDrawerContainer={{ padding: '16px 0px' }}
      >
        <VoucherDetails
          voucherDetailClose={voucherDetailClose}
          trackerData={trackerData}
          page={itemType === 'content' || itemType === 'event'}
          idempotentKey={idempotentKey}
          selectedProducts={selectedProducts}
          itemType={itemType}
          community={community}
        />
      </ModalDrawer>

      <ModalDrawer
        title={''}
        isOpen={checkoutVoucherError}
        onClose={() => {
          setCheckoutVoucherError(false)
          handleModalCleaner()
          onClose()
          voucherTracker(
            'close_voucher_code_failed_page',
            voucherCode,
          )
        }}
        sxDialogContainer={{
          '& .MuiDialogContent-root': {
            padding: '0px',
          },
          '& .MuiDialog-paper': {
            width: '500px',
          },
        }}
        sxDrawerContainer={{ padding: '16px 0px' }}
      >
        <VoucherErrorContent />
        {!isLoadingCart && (
          <ActionButton
            sx={{
              padding: '12px 24px',
              width: '100%',
            }}
            onClick={() => {
              trackEvent.content(
                'click_purchase_content',
                trackerData,
              )
              handleModalCleaner()
              onClose()
              setCheckoutVoucherError(false)
            }}
          >
            Mengerti
          </ActionButton>
        )}
      </ModalDrawer>

      {(invoiceVa || paymentInstruction?.invoice_id) && (
        <ModalDrawer
          title={'BCA Virtual Account'}
          isOpen={modalInstruction}
          onClose={() => {
            setModalInstruction(false)
            handleModalCleaner()
            onPaymentClose()
            if (itemType === 'coin') {
              preCheckout()
            }
          }}
          sxDialogContainer={{
            maxHeight: 750,
            '& .MuiDialogContent-root': {
              padding: '0px',
            },
            '& .MuiDialog-paper': {
              width: '500px',
            },
          }}
          sxDrawerContainer={{ padding: '16px 0px' }}
        >
          <div>
            {isLoadingVaInstruction && <DrawerSkeleton />}
            {paymentInstructionList && (
              <PaymentInstruction
                paymentInstructionList={paymentInstructionList}
                t={t}
              />
            )}
          </div>
          {!isLoadingCart && (
            <ActionButton
              sx={{
                padding: '12px 24px',
                width: '100%',
              }}
              onClick={() => {
                checkStatusHandler(
                  invoiceVa
                    ? invoiceVa
                    : paymentInstruction?.invoice_id,
                )
                setModalInstruction(false)
                handleModalCleaner()
              }}
            >
              {t('vaPaymentInstructionWidgetButton')}
            </ActionButton>
          )}
        </ModalDrawer>
      )}

      {vaStatus?.status?.toLowerCase() === 'pending' && (
        <ModalDrawer
          title={''}
          isOpen={isPendingState}
          onClose={() => {
            setIsPendingState(false)
            setModalInstruction(false)
            onPaymentClose()
            handleModalCleaner()
          }}
          sxDialogContainer={{
            maxHeight: 750,
            '& .MuiDialogContent-root': {
              padding: '0px',
            },
            '& .MuiDialog-paper': {
              width: '500px',
            },
          }}
          sxDrawerContainer={{ padding: '16px 0px' }}
        >
          <PendingStateContent
            title={paymentInstruction?.title}
            paymentInstructionList={paymentInstructionList}
            vaStatus={vaStatus}
          />
          {!isLoadingCart && (
            <ActionButton
              sx={{
                padding: '12px 24px',
                width: '100%',
              }}
              onClick={() => {
                setIsPendingState(false)
                setModalInstruction(true)
              }}
            >
              {t('back')}
            </ActionButton>
          )}
        </ModalDrawer>
      )}

      {vaStatus?.status?.toLowerCase() === 'paid' && (
        <SuccessStatePayment
          vaStatus={vaStatus}
          itemType="event"
          handleClose={modalVaStatusCloser}
          isOpen={isSuccessState}
        />
      )}
    </>
  )
}

export default DirectPaymentModal
