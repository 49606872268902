import MaintenanceBody from 'components/domains/Home/MaintenanceBody'
import MaxCapacityBody from 'components/domains/Home/MaxCapacityBody'
import LimitExceedBody from 'components/domains/Home/LimitExceedBody'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import RequestExceedBody from 'components/domains/Home/RequestExceedBody'
import React, { useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import StateImage from '../StateImage'
import Typography from '../Typography'
import ActionButton from '../Button/Action'

interface IErrorResponse {
  data: { code: string; message: string; data: {}; errors: [] }
  status: number
  statusText: string
}
interface CustomEventPayload {
  error: IErrorResponse
}

export default function ErrorState() {
  const [isOpen, setIsOpen] = useState(false)
  const [errorResponseState, setErrorResponseState] = useState({
    data: { code: '', message: '', data: {}, errors: [] },
    status: 0,
    statusText: '',
  })
  const theme = useTheme()

  useEffect(() => {
    window.addEventListener(
      'openGlobalError',
      (e: CustomEvent<CustomEventPayload>) => {
        setIsOpen(true)
        setErrorResponseState(e.detail?.error)
      },
      false,
    )
  }, [])

  const handleCloseError = () => {
    setIsOpen(false)
    setErrorResponseState({
      data: { code: '', message: '', data: {}, errors: [] },
      status: 0,
      statusText: '',
    })
  }
  const ErrorBody = () => {
    if (errorResponseState?.status === 503) {
      return <MaintenanceBody />
    } else if (errorResponseState?.status === 429) {
      if (
        errorResponseState?.data?.code === 'MAX_CAPACITY_EXCEEDED'
      ) {
        return <MaxCapacityBody backButtonAction={handleCloseError} />
      } else if (
        errorResponseState?.data?.code === 'RATE_LIMIT_EXCEEDED'
      ) {
        return <LimitExceedBody backButtonAction={handleCloseError} />
      } else if (
        errorResponseState?.data?.code ===
        'DEEPLINK_REQUEST_LIMIT_EXCEEDED'
      ) {
        return (
          <RequestExceedBody backButtonAction={handleCloseError} />
        )
      }
    } else {
      return (
        <Box>
          <StateImage
            sx={{
              marginBottom: '24px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '35px',
            }}
            type="error"
          />
          <Typography
            variant="h3"
            color={theme.palette.text.primary}
            sx={{ marginBottom: '10px' }}
          >
            Terjadi kesalahan sistem
          </Typography>
          <Typography
            variant="normalRegular"
            color={theme.palette.text.secondary}
            sx={{ marginBottom: '24px', display: 'block' }}
          >
            Maaf terjadi kesalahan pada sistem saat menampilkan
            halaman. Silakan coba beberapa saat lagi
          </Typography>

          <ActionButton
            sx={{
              padding: '12px 24px',
              width: '100%',
            }}
            onClick={handleCloseError}
          >
            Tutup
          </ActionButton>
        </Box>
      )
    }
  }

  return (
    isOpen && (
      <ModalDrawer
        title=""
        isOpen={isOpen}
        onClose={handleCloseError}
        sxDialogContainer={{
          '& .MuiDialogContent-root': {
            padding: '0px',
          },
          '& .MuiDialog-paper': {
            width: '500px',
          },
        }}
        sxDrawerContainer={{ padding: '16px 0px' }}
      >
        {ErrorBody()}
      </ModalDrawer>
    )
  )
}
