import axios, {
  AxiosPromise,
  AxiosRequestConfig,
  Method,
} from 'axios'
import {
  CacheRequestConfig,
  setupCache,
} from 'axios-cache-interceptor'
import { Auth } from 'aws-amplify'
import apiConfig from 'configs/api'
import isEmpty from 'helpers/is-empty'
import { NextPageContext } from 'next'

import afterLogin from 'helpers/auth/after-login'
import { getBrowser } from 'helpers/user-agent'
import { getFingerprint } from 'helpers/user-device'
import appConfig from 'configs/app'
import { postRefreshToken } from 'services/user'
import { sendToLog } from './sendToLog'
import authConfig from 'configs/auth'
import { getLocalStorage, getWithExpiry } from 'helpers/local-storage'
import Qs from 'qs'
import redirect from 'helpers/redirect'
import { forceLogout } from './forceLogout'
import { addBreadcrumb } from 'helpers/log'
import { getQueueToken } from 'helpers/queue'

const baseApi = apiConfig.baseApiUrl
interface IFetchApiParams extends Pick<CacheRequestConfig, 'cache'> {
  apiHost?: string
  url?: string
  method?: Method
  token?: string
  data?: object
  headers?: object
  params?: object
  context?: NextPageContext
  withoutHeaders?: boolean
  paramsSerializerIndex?: boolean | null
  customHeaders?: object
}

const DEFAULT_ERROR_MESSAGE =
  'Mohon maaf terjadi kesalahan, silakan coba beberapa saat lagi.'

const fetchApi = ({
  apiHost = baseApi,
  url,
  method = 'GET',
  token,
  data = {},
  headers = {},
  params = {},
  withoutHeaders = false,
  cache = {
    interpretHeader: true,
    cacheTakeover: false,
  },
  customHeaders,
}: IFetchApiParams): AxiosPromise => {
  const isAuth = !isEmpty(token)
  const queueToken = getQueueToken()
  const lang = getLocalStorage('i18nextLng') || appConfig.defaultLang
  const config: AxiosRequestConfig = {
    baseURL: apiHost,
    params: {
      ...params,
    },
    paramsSerializer: (params) => {
      return Qs.stringify(params, { arrayFormat: 'repeat' })
    },
    method,
    url,
    timeout: 20000,
  }

  if (!isEmpty(data)) config.data = { ...data }

  if (!withoutHeaders) {
    if (customHeaders) {
      config.headers = { ...customHeaders }
    } else {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Language: lang,
        Channel: 'WEB',
        'Country-Code': 'ID',
        'Channel-Device': getBrowser(false),
        'Channel-Fingerprint': getFingerprint(),
        'Channel-App-Version': appConfig.publicVersion,
        ...headers,
        ...(isAuth && { Authorization: `${token}` }),
        ...(!isEmpty(queueToken) && {
          'Queue-Token': queueToken,
        }),
      }
    }
  }

  const axiosInstance = setupCache(axios.create())

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },

    async function (error) {
      if (error?.response?.status !== 401) sendToLog(error)
      const originalRequest = error.config
      if (error?.response) {
        if (
          error.response.status === 401 &&
          error.response.data.code !== 'REFRESH_TOKEN_EXPIRED'
        ) {
          if (!originalRequest._retry) {
            originalRequest._retry = true
            const authMethod = getLocalStorage('authMethod')
            try {
              if (['email', 'phoneNumber'].includes(authMethod)) {
                const currentRefreshToken = await postRefreshToken()
                if (
                  currentRefreshToken?.data?.code === 'SUCCESS' &&
                  currentRefreshToken.data?.data?.AuthenticationResult
                ) {
                  const { IdToken: token, AccessToken: accessToken } =
                    currentRefreshToken.data.data.AuthenticationResult
                  const refreshToken = getWithExpiry(
                    authConfig.refreshTokenName,
                  )
                  originalRequest.headers.Authorization = `${token}`

                  afterLogin(
                    { token, accessToken, refreshToken },
                    () => null,
                    '/',
                    true,
                  )
                }
              } else {
                const currentSession = await Auth.currentSession()
                const token = currentSession
                  .getIdToken()
                  .getJwtToken()
                const accessToken = currentSession
                  .getAccessToken()
                  .getJwtToken()
                originalRequest.headers.Authorization = `${token}`
                afterLogin(
                  { token, accessToken },
                  () => null,
                  '/',
                  true,
                )
              }
              return axiosInstance(originalRequest)
            } catch (error) {
              // force logout if error
              forceLogout()
            }
          }
        } else if (error.response.status === 503) {
          redirect('/maintenance')
        } else if (error.response.status === 429) {
          if (
            error.response.data.code === 'MAX_CAPACITY_EXCEEDED' ||
            error.response.data.code === 'RATE_LIMIT_EXCEEDED' ||
            error.response.data.code ===
              'DEEPLINK_REQUEST_LIMIT_EXCEEDED'
          ) {
            const event = new CustomEvent('openGlobalError', {
              detail: {
                error: error.response,
              },
            })
            window.dispatchEvent(event)
          } else if (
            error.response.data.code === 'WAITING_ROOM_PROTECTED'
          ) {
            window.location.href = `${appConfig.waitingRoomUrl}?queue_token=${error.response.data.data.queue_token}`
          }
        }
        addBreadcrumb(
          'error api client',
          error?.response?.status,
          'error',
          {
            data: error?.response?.data,
            error,
          },
        )
        return Promise.reject(error)
      }

      const newError = {
        response: {
          data: {
            name: 'client_side_error',
            message: DEFAULT_ERROR_MESSAGE,
          },
        },
      }
      return Promise.reject(newError)
    },
  )
  return axiosInstance
    .request({ ...config, cache })
    .then((res) => {
      return res
    })
    .catch((error) => {
      throw error
    })
}

export default fetchApi
